<template>
  <div>
    <h4 class="text-center">Selecione as mesas de deseja separar</h4>
    <hr />
    <div class="mesaListSeparar">
      <div
        v-for="mesa in mesa.uniao"
        @click="selectMesaSepare(mesa)"
        :key="`mesa_juntada_${mesa}`"
        :class="listToSpeare.includes(mesa) ? 'selected' : ''"
      >
        <span class="icon mr-1">
          <b-icon-check v-if="!listToSpeare.includes(mesa)" />
          <b-icon-check-circle v-if="listToSpeare.includes(mesa)" />
        </span>
        {{ getMesaNome(mesa) }}
      </div>
    </div>
    <b-btn
      block
      variant="success"
      @click="separar()"
      v-if="listToSpeare && listToSpeare.length > 0"
      ><b-icon-code-slash /> SEPARAR</b-btn
    >

    <b-modal
      id="modal-separar-mesas-items"
      title="Deseja dividir os pedidos?"
      hide-footer
    >
      <b
        >Para dividir os pedidos selecione qual mesa ficará com o pedido
        selecionado</b
      >
      <hr />
      <div class="pedidosParaSeparar">
        <div
          v-for="p in mesaObj.pedidos"
          :key="`pedidoSepara_${p.cod_pedido}_${Math.random()}`"
          class="pedido row"
        >
          <div class="col-10 col-sm-6 pb-2">
            <b>{{ p.prod }} {{ p.prod2 ? " | " + p.prod2 : "" }}</b>
          </div>
          <div class="col-2 pl-1 pr-0">
            <small
              ><b>{{ p.vl_total | currencyMask }}</b></small
            >
          </div>
          <div class="col-12 col-sm-4 mesaSelecionadaNoPedido pb-3">
            <div
              v-for="mesa in mesa.uniao"
              @click="selectMesaDestino(p, mesa)"
              :key="`mesa_juntada_${mesa}`"
              :class="
                p && p.mesaDestino && p.mesaDestino == mesa ? 'selected' : ''
              "
            >
              <span class="icon mr-1">
                <b-icon-check
                  v-if="!p || !p.mesaDestino || p.mesaDestino !== mesa"
                />
                <b-icon-check-circle
                  v-if="p && p.mesaDestino && p.mesaDestino == mesa"
                />
              </span>
              {{ getMesaNome(mesa).replace("Mesa", "") }}
            </div>
          </div>
        </div>
        <b-btn
          variant="success"
          v-if="
            mesaObj.pedidos.filter(
              (x) => x.mesaDestino && x.mesaDestino != null
            ).length == mesaObj.pedidos.length
          "
          >DIVIDIR ITENS E SEPARAR</b-btn
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    mesaObj: Object,
    mesa: Object,
    getMesaNome: Function,
  },
  mounted() {},
  data() {
    return {
      listToSpeare: [],
    };
  },
  watch: {},
  computed: {},
  methods: {
    selectMesaDestino(p, mesa) {
      console.log("setting", p, mesa);
      if (p.mesaDestino && p.mesaDestino == mesa) {
        p.mesaDestino = null;
      } else {
        p.mesaDestino = mesa;
      }
      console.log("settinged", p);
      this.$forceUpdate();
    },
    separar() {
      this.$bvModal.show("modal-separar-mesas-items");
    },
    selectMesaSepare(mesa) {
      let has = this.listToSpeare.find((x) => x == mesa);
      if (has) {
        this.listToSpeare = this.listToSpeare.filter((x) => x != mesa);
      } else {
        this.listToSpeare.push(mesa);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pedidosParaSeparar {
  display: flex;
  flex-direction: column;
  .pedido {
    padding: 15px;
    border-bottom: 1px solid #ccc;
    .mesaSelecionadaNoPedido {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      div {
        cursor: pointer;
        padding: 10px;
        margin-right: 5px;
        border-radius: 5px;
        background: #f9f9f9;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #f9f9f9;
      }
      .selected {
        border: 2px solid green;
        .icon {
          color: green;
          opacity: 1;
        }
      }
    }
  }
}
.mesaListSeparar {
  display: flex;
  flex-direction: column;
  div {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
    cursor: pointer;
    .icon {
      opacity: 0.2;
    }
    &:hover {
      .icon {
        opacity: 1;
        color: green;
      }
    }
  }
  .selected {
    border-color: green;
    border-width: 4px;
    .icon {
      opacity: 1;
      color: green;
      font-size: 15px;
    }
  }
}
</style>
