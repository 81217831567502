<template>
  <div>
    <div
      class="mesa"
      :class="mesaVisu ? 'mesaSelecionada' : ''"
      @click="openmesas"
    >
      <i class="fa fa-table mr-1" />
      {{
        !mesaVisu &&
        mesaObj &&
        mesaObj.id &&
        mesa &&
        mesa.uniao &&
        mesa.uniao.length > 0
          ? "Mesas Juntadas"
          : mesa.uniao && mesa.uniao.length > 0
          ? getMesaNome(mesaVisu)
          : mesa.mesa
      }}
    </div>
    <b-modal
      id="modal-mesas"
      hide-footer
      title="Selecione a mesa que deseja visualizar"
    >
      <div class="caixaTopoMesaInsider">
        <div class="listamesas">
          <div class="cmd" @click="selecionarmesa(null)">
            <i class="fa fa-table mr-1" />
            Mesas Juntadas
          </div>
          <hr />
          <div
            class="cmd"
            v-for="mesa in mesa.uniao"
            :key="`mesa_${mesa}_${Math.random()}`"
            @click="selecionarmesa(mesa)"
          >
            <i class="fa fa-table mr-1" /> {{ getMesaNome(mesa) }}
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    mesa: Object,
    mesas: Array,
    mesaObj: Object,
    mesaVisu: Number,
  },
  mounted() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {
    openmesas() {
      this.$bvModal.show("modal-mesas");
    },
    getMesaNome(cod_mesa) {
      // console.log("mesa", cod_mesa, this.mesas);
      return this.mesas.find((x) => x.cod_mesa === cod_mesa).mesa || "";
    },
    getMesa(cod_mesa) {
      return this.mesas.find((x) => x.cod_mesa === cod_mesa);
    },
    selecionarmesa(mesa) {
      this.$bvModal.hide("modal-mesas");
      this.$emit("select", mesa);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mesas.scss";
</style>
