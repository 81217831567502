<template>
  <div  class="p-3 containerPedidos scrollPersonalizadoWH">
    <h3 class="text-center">
      <b-icon-cash-coin /> Pagamentos
      {{ mesaVisu ? getMesaNome(mesaVisu) : "Total" }}
    </h3>
    <mesa-new-pedido-resumo
      :mesaObj="mesaObj"
      :mesaVisu="mesaVisu"
      :getMesaNome="getMesaNome"
      :comanda="comandaSel"
      @addPgto="addPgto"
      @removerTaxa="removeTaxa()"
      :showFechar="(comandaSel && !comandaSel.dt_fechada) || !comandaSel"
      :showButtons="(comandaSel && !comandaSel.dt_fechada) || !comandaSel"
      @fecharMesa="fecharMesa"
      @gotoPG="addPgto"
      @addItem="addPedido"


    />
    <div class="listaPagamentos mt-2 " v-if="mesaObj && mesaObj.pagamentos">
      <b>Pagamentos</b>
      <table class="table table-hover" style="font-size: 12px">
        <thead>
          <tr>
            <th>Forma de Pagamento</th>
            <th>Mesa</th>
            <th>Comanda</th>
            <th class="text-right">Valor</th>
          </tr>
        </thead>
        <tbody>
          <tr
            style="cursor: pointer"
            @click="editarPagamento(pg)"
            v-for="pg in pagamentos"
            :key="`mesaPgto${pg.id}`"
          >
            <td>{{ pg.forma }}</td>
            <td>{{ pg.ratear == 1 ? "Todas" : getMesaNome(pg.cod_mesa) }}</td>
            <td>{{ !pg.comanda ? "Todas" : pg.comanda }}</td>
            <td style="min-width: 100px" class="text-right">
              {{ pg.valor | currencyMask }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="!mesaObj.pagamentos || mesaObj.pagamentos.length > 0">
        <small>(para editar / excluir clique encima do pagamento)</small>
      </div>
      <div class="d-flex" style="margin-bottom: 400px;">
        <div :class="`btPlus`" @click="addPagamento()" v-if="(comandaSel && !comandaSel.dt_fechada) || !comandaSel && !mesaObj.dt_fechada">
          <i class="fa fa-plus" /> Adicionar Pagamento
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MesaNewPedidoResumo from "./MesaNewPedidoResumo.vue";
export default {
  components: { MesaNewPedidoResumo },
  props: {
    mesaObj: Object,
    mesaVisu: Number,
    getMesaNome: Function,
    editPagamento: Function,
    addPagamento: Function,
    comandaSel: Object,
    addPedido: Function,
  },
  mounted() {},
  data() {
    return {};
  },
  watch: {
    comandaSel: function () {
      this.$forceUpdate();
    },
  },
  computed: {
    pagamentos() {
      return this.mesaObj.pagamentos.filter(
        (x) =>
          (this.mesaVisu ? x.cod_mesa == this.mesaVisu && !x.ratear : true) &&
          (this.comandaSel ? x.comanda_id == this.comandaSel.id : true)
      );
    },
  },
  methods: {
    editarPagamento(pg){
      if(this.comandaSel && this.comandaSel.dt_fechada){
        return;
      }
      this.editPagamento(pg);
    },
    addPgto() {
      if (!this.mesaObj.dt_fechada) {

        this.$emit("addPgto");
      }
    },
    removeTaxa() {
      if (!this.mesaObj.dt_fechada) {
        console.log("removeTaxa 1");
        this.$emit("removeATaxa");
      }
    },
    fecharMesa() {

      if (!this.mesaObj.dt_fechada) {
        console.log("fechar02")

        this.$emit('fecharMesa');
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.containerPedidos {
  overflow: hidden;
  overflow-y: auto;
  // width: 100%;
  // height: 100%;

    //width: 100%;
  //height: 100%;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0px;
    right: 00px;
    bottom: 0px;
    padding-bottom: 60px;
    overflow: auto;


  display: flex;
  flex-direction: column;
  padding: 5px;
  padding-bottom: 600px;
}
.btPlus {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-color: green;
  color: white;
  border-radius: 50%;
  font-size: 50px;
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  i {
    font-size: 50px;
    margin-right: 30px;
    margin-left: 3px;
  }
  transition: all 0.4s ease;
  overflow: hidden;
  display: flex;
  flex-direction: row;

  padding: 16px;
  &:hover {
    width: 250px;
    border-radius: 10px;
  }
}
.btFecha {
  background-color: rgb(46, 46, 162);
  font-size: 20px;
  i {
    font-size: 50px;
    margin-right: 30px;
    margin-left: 5px;
  }
  transition: all 0.4s ease;
  overflow: hidden;
  display: flex;
  flex-direction: row;

  padding: 16px;
  &:hover {
    width: 250px;
    border-radius: 10px;
  }
}
</style>
