<template>
  <div>
    <div class="mesaTrocarList mb-4" v-if="mesa">
      <b>Destino</b> <br />
      <v-select
        style="width: 100%"
        :clearable="false"
        :options="mesasDisponiveis"
        v-model="listaMesa[0].mesaDestino"
        :reduce="(it) => it.cod_mesa"
        label="mesa"
      />

      <!-- <div v-if="mesa && mesa.juncao && mesa.juncao.length>0">
        <b-checkbox switch v-model="manterUnida">Manter Unidas</b-checkbox>
        
      </div> -->
      <!-- <div
        v-for="mesa in listaMesa"
        :key="`mesa_troca${mesa}_${Math.random()}`"
        class="mesaTroca"
      >
        <div class="pt-1">
          <b>Origem</b> <br />
          {{ getMesaNome(mesa.cod_mesa) }}
        </div>
        <div>
          <b>Destino</b> <br />
          <v-select
            style="width: 100%"
            :clearable="false"
            :options="mesasDisponiveis"
            v-model="mesa.mesaDestino"
            :reduce="(it) => it.cod_mesa"
            label="mesa"
          />
        </div> 
      </div>-->
    </div>
    <b-btn variant="success" block @click="trocar()"
      ><i class="fas fa-arrows-alt-h mr-1"></i> Trocar mesas</b-btn
    >
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    mesaObj: Object,
    mesa: Object,
    getMesaNome: Function,
    mesas: Array,
  },
  mounted() {
    // console.log("mesa", this.mesa, this.mesaObj);
    // if (this.mesa && (!this.mesa.uniao || this.mesa.uniao.length <= 0)) {
    //   this.mesa.uniao = [this.mesa.cod_mesa];
    // }
    // this.listaMesa = this.mesa.uniao.map((it) => ({
    //   cod_mesa: it,
    //   mesaDestino: it,
    // }));
    this.listaMesa = [
      { cod_mesa: this.mesa.cod_mesa, mesaDestino: this.mesa.cod_mesa },
    ];
    if (this.mesa && this.mesa.uniao && this.mesa.uniao.length > 0) {
      this.listaMesa = this.mesa.uniao.map((it) => ({
        cod_mesa: it,
        mesaDestino: it,
      }));
      this.manterUnida = true;
    }
    this.mesasDisponiveis = this.mesas.filter(
      (x) =>
        (!x.id || x.id == 0) &&
        x.cod_mesa !== this.mesaObj.cod_mesa &&
        (!this.mesaObj.uniao ||
          this.mesaObj.uniao.length <= 0 ||
          !this.mesaObj.uniao.includes(x.cod_mesa))
    );
  },
  data() {
    return {
      listaMesa: [],
      mesasDisponiveis: [],
      manterUnida: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    trocar() {
      console.log("log", this.listaMesa);
      let err = 0;
      for (let m of this.listaMesa) {
        let hasOther = this.listaMesa.find(
          (x) => x.cod_mesa != m.cod_mesa && x.mesaDestino == m.mesaDestino
        );
        if (hasOther) {
          this.$swal(
            "Atenção!",
            this.getMesaNome(hasOther.cod_mesa) +
              " está com a mesmo destino da " +
              this.getMesaNome(m.cod_mesa),
            "error"
          );
          err++;
        }
      }
      if (err == 0) {
        this.$swal({
          title: "Trocar as mesas informadas?",
          text: "As mesas que estiverem selecionado o destino como a própria não sofreram alteração!",
          icon: "question",
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Trocar!",
        }).then(async (resp) => {
          if (resp.isConfirmed) {
            this.$socket.emit("mesas:trocarMesa", {
              manterUnida: this.manterUnida,
              mesaMestre: this.mesa,
              listaMesa: this.listaMesa,
            });
            this.$swal(
              "Solicitação efetuada!",
              "Aguarde a confirmação!",
              "success"
            );
            this.$emit("close", { cod_mesa: this.listaMesa[0].mesaDestino } );
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mesaTrocarList {
  display: flex;
  flex-direction: column;
  .mesaTroca {
    margin-bottom: 15px;
    border: 1px solid #f9f9f9;
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    div {
      width: 50%;
      // text-align: center;
    }
  }
}
</style>
