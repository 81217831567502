<template>
  <div>
    <div class="mesaTrocarList mb-4" v-if="mesa">
      <div
        class="mb-4"
        style="
          background-color: #fafafa;
          border-radius: 20px;
          padding: 20px;
          text-align: center;
        "
      >
        <i class="fa fa-barcode mr-1"></i> {{ comandaSel.comanda }}
      </div>
     
      <b>Destino</b> <br />
     
      <v-select
        style="width: 100%; font-size:20px"
        :clearable="false"
        :options="mesasDisponiveis"
        v-model="mesaDestino"
        :reduce="(it) => it"
        label="mesa"
        
      />

      <!-- <div v-if="mesa && mesa.juncao && mesa.juncao.length>0">
        <b-checkbox switch v-model="manterUnida">Manter Unidas</b-checkbox>
        
      </div> -->
      <!-- <div
        v-for="mesa in listaMesa"
        :key="`mesa_troca${mesa}_${Math.random()}`"
        class="mesaTroca"
      >
        <div class="pt-1">
          <b>Origem</b> <br />
          {{ getMesaNome(mesa.cod_mesa) }}
        </div>
        <div>
          <b>Destino</b> <br />
          <v-select
            style="width: 100%"
            :clearable="false"
            :options="mesasDisponiveis"
            v-model="mesa.mesaDestino"
            :reduce="(it) => it.cod_mesa"
            label="mesa"
          />
        </div> 
      </div>-->
    </div>
    <b-btn variant="success" block @click="trocar()"
      ><i class="fas fa-arrows-alt-h mr-1"></i> Transferir para {{ mesaDestino? mesaDestino.mesa:'mesa selecionada' }}</b-btn
    >
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    mesaObj: Object,
    mesa: Object,
    getMesaNome: Function,
    mesas: Array,
    comandaSel: Object,
  },
  mounted() {
    // console.log("mesa", this.mesa, this.mesaObj);
    // if (this.mesa && (!this.mesa.uniao || this.mesa.uniao.length <= 0)) {
    //   this.mesa.uniao = [this.mesa.cod_mesa];
    // }
    // this.listaMesa = this.mesa.uniao.map((it) => ({
    //   cod_mesa: it,
    //   mesaDestino: it,
    // }));

    if (this.mesa && this.mesa.uniao && this.mesa.uniao.length > 0) {
      this.listaMesa = this.mesa.uniao.map((it) => ({
        cod_mesa: it,
        mesaDestino: it,
      }));
      this.manterUnida = true;
    }
    this.mesasDisponiveis = this.mesas.filter(
      (x) =>

        x.cod_mesa !== this.mesaObj.cod_mesa 

    );
  },
  data() {
    return {
      listaMesa: [],
      mesasDisponiveis: [],
      manterUnida: false,
      mesaDestino:null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    trocar() {
      if (this.mesaDestino == null) {
        this.$swal("Informe a mesa de destino!", "Atenção", "error")
        return;
      }

      console.log("log", this.listaMesa);
      let err = 0;
  
      if (err == 0) {
        this.$swal({
          title: "Confirmar a transferência de comanda?",
          text: "Caso a mesa de destino não esteja aberta, será aberta automaticamente!",
          icon: "question",
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Transferir!",
        }).then(async (resp) => {
          if (resp.isConfirmed) {
            let obj = {
              comanda_id: this.comandaSel.comanda_id,
              mesa_controle_id: this.mesaObj.id,
              cod_mesa: this.mesaDestino.cod_mesa,
              dt_aberta: this.mesaObj.dt_aberta,
              cod_empresa: this.mesaObj.cod_empresa,
              cod_usuario: this.$store.state.auth.user.cod_usuario,

            };
            console.log("obj", obj);
            this.$socket.emit("mesas:trocarMesaComanda", obj);
            this.$swal(
              "Solicitação efetuada!",
              "Aguarde a confirmação!",
              "success"
            );
            this.$emit("close", { cod_mesa: this.mesaDestino.cod_mesa } );
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mesaTrocarList {
  display: flex;
  flex-direction: column;
  .mesaTroca {
    margin-bottom: 15px;
    border: 1px solid #f9f9f9;
    background-color: #f9f9f9;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    div {
      width: 50%;
      // text-align: center;
    }
  }
}
</style>
