import { render, staticRenderFns } from "./MesaNewPedidoResumo.vue?vue&type=template&id=0b87e216&scoped=true"
import script from "./MesaNewPedidoResumo.vue?vue&type=script&lang=js"
export * from "./MesaNewPedidoResumo.vue?vue&type=script&lang=js"
import style0 from "./MesaNewPedidoResumo.vue?vue&type=style&index=0&id=0b87e216&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b87e216",
  null
  
)

export default component.exports