<template>
  <div class="mb-5">
    <b-card
      class=""
      body-class="pb-4"
    >
      <div
        v-if="comanda && comanda.dt_fechada"
        class="alert bg-danger text-white w-100 p-2 text-center"
      >
        <h4>
          <b-icon-exclamation-circle-fill></b-icon-exclamation-circle-fill>
          COMANDA FECHADA
        </h4>
      </div>
      <b-card-title>
        <h4 class="mb-2 d-flex justify-content-between">
          <div>
            {{
              `${comanda ? "" : mesaObj.mesa + ""}  
            `
            }}

            <span
              v-if="comanda"
              class="d-flex"
              :class="{ 'flex-column': $store.state.isMobile }"
            >
              <div>
                <i class="fa fa-barcode mr-1"></i>
                {{ getComandaNome(comanda, 0) }}
              </div>
              <span
                class="p"
                style="font-size: 12px"
                v-if="
                  getComandaNome(comanda, 1) &&
                  getComandaNome(comanda, 1).trim() != ''
                "
              >
                <div class="pl-4 pt-2">
                  <i class="fa fa-user mr-1"></i>
                  {{ getComandaNome(comanda, 1) }}
                  <br v-if="$store.state.isMobile" />
                  <i
                    :class="
                      'fa fa-phone' +
                      (!$store.state.isMobile ? ' ml-2 mr-1' : '')
                    "
                  ></i>
                  {{ getComandaNome(comanda, 2) }}
                </div>
              </span>
            </span>
          </div>
          <div
            v-if="
              mesaObj &&
              mesaObj.reserva &&
              mesaObj.reserva.id &&
              mesaObj.reserva.id > 0
            "
            style="font-size: 10px"
          >
            <b-icon-calendar /> Reserva de {{ mesaObj.reserva.nome }}
          </div>

          <div class="text-right">
            <small>Conta nº {{ mesaObj.id || "" }}</small>
          </div>
        </h4>
      </b-card-title>

      <div class="d-flex justify-content-between mt-4">
        <div>Subtotal</div>
        <div>{{ subtotal | currencyMask }}</div>
      </div>
      <div class="d-flex justify-content-between">
        <div>
          Taxa Serviço
          <span v-if="comanda && comanda.id">({{ taxa_servico }}%)</span>
        </div>
        <div>{{ taxa | currencyMask }}</div>
      </div>
      <div class="d-flex justify-content-between font-weight-bold">
        <div>Total</div>
        <div>{{ total | currencyMask }}</div>
      </div>
      <div class="d-flex justify-content-between font-weight-bold mt-3 menor">
        <div>Pago</div>
        <div>{{ pago | currencyMask }}</div>
      </div>

      <div
        class="d-flex justify-content-between font-weight-bold menor"
        style="color: red"
      >
        <div>Restante</div>
        <div>{{ restante | currencyMask }}</div>
      </div>

      <div
        class="d-flex justify-content-between font-weight-bold menor"
        style="color: #ccc"
        v-if="mesaVisu"
      >
        <div>Pago Rateado</div>
        <div>{{ pagoRateado | currencyMask }}</div>
      </div>
      <div
        class="d-flex justify-content-end"
        style="position: absolute; bottom: -25px; right: -0px"
        v-if="!mesaObj.dt_fechada"
      >
        <b-btn
          size="lg"
          variant="info"
          class="mr-1"
          @click="addItem"
          v-if="showFechar"
        >
          <i class="fa fa-shopping-cart"></i> Item
        </b-btn>

        <b-btn
          size="lg"
          variant="info"
          class="mr-1"
          @click="fechar"
          v-if="showFechar"
        >
          <i class="fas fa-clipboard-check"></i> Fechar
        </b-btn>

        <b-btn
          v-if="showButtons"
          size="lg"
          variant="danger"
          class="mr-1"
          @click="removeTaxa"
          ><b-icon-percent
        /></b-btn>
        <b-btn
          v-if="showButtons"
          size="lg"
          variant="success"
          class="mr-1"
          @click="addPgto"
          ><b-icon-currency-dollar
        /></b-btn>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    mesaObj: Object,
    mesaVisu: Number,
    getMesaNome: Function,
    comanda: Object,
    showFechar: {
      type: Boolean,
      default: false,
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    // console.log("COMANDASEL", this.comanda);
  },
  data() {
    return {};
  },
  watch: {
    "$.props": {
      deep: true,
      handler() {
        console.log("COMAND", this.comanda);
      },
    },
  },
  computed: {
    subtotal() {
      return this.mesaObj.pedidos
        .filter((x) => !x.excluido || x.excluido == 0)
        .filter(
          (x) =>
            (this.mesaVisu ? x.cod_mesa == this.mesaVisu : true) &&
            (this.comanda ? x.comanda_id == this.comanda.id : true)
        )
        .reduce((ret, vl) => {
          ret += vl.vl_total;
          return ret;
        }, 0);
    },
    taxa_servico() {
      return this.comanda
        ? this.comanda.taxa_servico
        : this.mesaObj.taxa_servico;
    },
    taxa() {
      return this.comanda
        ? this.comanda.vl_taxa_servico
        : this.mesaObj.vl_taxa_servico;
    },
    total() {
      return this.subtotal + this.taxa;
    },
    pago() {
      return this.mesaObj.pagamentos
        ? this.mesaObj.pagamentos
            .filter(
              (x) =>
                (this.mesaVisu ? x.cod_mesa == this.mesaVisu : true) &&
                (this.comanda ? x.comanda_id == this.comanda.id : true)
            )
            .reduce((ret, vl) => {
              ret += vl.valor;
              return ret;
            }, 0)
        : 0;
    },
    pagoRateado() {
      return this.mesaObj.pagamentos
        ? this.mesaObj.pagamentos
            .filter((x) => x.ratear == 1)
            .reduce((ret, vl) => {
              ret += vl.valor;
              return ret;
            }, 0)
        : 0;
    },
    restante() {
      return this.total - this.pago;
    },

  },
  methods: {
    addItem(){
      this.$emit("addItem");
    },
    getComandaNome(comanda, index) {
      if (comanda.nome.includes("|")) {
        return comanda.nome.split("|")[index];
      } else {
        return comanda.nome;
      }
    },
    addPgto() {
      this.$emit("addPgto");
    },
    removeTaxa() {
      console.log("removeTaxa 00");
      this.$emit("removeTaxa");
      this.$emit("removerTaxa", 123);
      this.$forceUpdate();
      console.log("removeTaxa 00 emitted");
    },
    fechar() {
      console.log(this.total, this.pago);
      if (this.total - 0.01 > this.pago) {
        this.$swal("Atenção", "Valor pago inferior ao da conta!", "error");
        this.$emit("gotoPG");
        return;
      } else {
        console.log("fechar01");
        this.$emit("fecharMesa");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.menor {
  font-size: 10px !important;
}
</style>
